.nav {
  display: flex;
  height: 100px;
  background-color: #111;
  align-items: center;
  justify-content: space-between;
  opacity: 9;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
  transition: all 0.5s;
  opacity: 0.8;
}

.nav_logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2rem;
  cursor: pointer;
}

.nav_icon:hover {
  transform: scale(1.03);
  transition: all 0.3s ease;
}


.nav_menu {
  display: flex;
  gap: 1.2rem;
  margin-right: 3rem;
  align-items: center;
}

.nav_menu > .a {
  text-decoration: none;
  font-size: .9rem;
  font-weight: 900;
  color: #e9e9e9;
  text-shadow: black 0px 1px 1px;
  transition: all 0.5s ease;
  text-align: center;
}

.nav_menu > a:hover {
  transition: all 0.5s ease-out;
  color: rgb(251, 33, 117);
}

.nav_menu > a:active {
  color: #00a37d;
}

.nav_menu > a:active::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

.nav_menu > .btn {
  width: 120px;
  padding: 6px 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: .9rem;
  background-color: orange;
  cursor: pointer;
  font-weight: 900;
  letter-spacing: 1.4px;
  transition: all 0.5s ease;
  text-align: center;
}

.nav_menu > .btn:hover {
  transition: all 0.5s ease-out;
  background-color: rgb(251, 33, 117);
  border: 1px solid orange;
  color: #fff;
}

.nav_menu > .btn:active {
  transform: scale(.9);
  transition: all 0.5s ease-out;
  background-color: yellow;
  color: black;
}


/* MOBILE */
.menu_icons {
  display: none;
  padding: .7rem;
  color: #fff;
}


@media only screen and (max-width: 999px) {

  /* MOBILE  View Nav*/
  .nav_logo {
    margin-left: 0;
  }

  .nav {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);

    z-index: 999;
    padding: 0 10px;
    width: 95%;
    top: 5px;
    left: 50%;
    transform: translate(-50%);
    transition: all 0.3s ease;
  }

  .menu_icons {
    display: block;
    padding: .7rem;
    color: #fff;
  }

  .menu_icons {
    color: #fff;
  }

  .nav_menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: blueviolet;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 90px 0 30px 0;
    backdrop-filter: blur(4px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .37);
    z-index: -1;
    transition: all 0.3s ease-in-out;
    gap: 0;
    border-radius: 8px;
  }

  .nav_menu.active {
    left: 0;
    margin: 0;
  }

  .menu_icons:hover {
    color: orange;
    transition: all 0.2s ease;
  }

  .a {
    display: block;
    padding: 1rem 3rem;
    widows: 100%;
    font-size: 1rem;
    text-align: center;
  }

  .a:hover {
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(40px);
  }

  .nav_menu > .btn {
    align-self: center;
    text-align: center;
    padding: 12px;
  }

  .nav_menu > .btn {
    width: 40%;
    height: 50px;
    font-size: 1rem;
    font-weight: 900;
    margin-top: .5rem;
  }
}