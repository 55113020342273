/* Contestants Cards */
.cards {
  max-width: 900px;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: nowrap;
  gap: 4rem 1rem;
  justify-items: center;
  align-items: center;
  justify-content: space-evenly;
}

.card_wrapper {
  min-width: 320px;
  max-width: 480px;
  //border: 2px solid gray;
  position: relative;
  background-color: aliceblue;
  padding-bottom: 2rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

@media (min-width: 400px) {
  .card_wrapper {
    min-width: 100%;
  }
}

.card_wrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card_details {
  display: flex;
  flex-direction: column;
}

.card_wrapper > .card_details > p {
  text-align: center;
  padding: 8px;
  background-color: rgb(30, 30, 235);
  color: #fff;
  border-radius: 4px;
  width: 200px;
  align-self: center;
  margin: 1rem;
  font-weight: 800;
  font-size: .9rem;
  box-shadow: 0 3px 10px 0 #aaa;
}

.card_wrapper > .card_details > h1 {
  text-align: center;
  font-weight: 900;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  text-shadow: black 0px 1px 1px;
}

.card_wrapper > .card_details > .profile > .button,
.card_wrapper > .card_details > .profile > .button > span {
  width: 200px;
  justify-content: center;
  align-self: center;
  height: 50px;
  font-size: 1.1rem;
  font-weight: 900;
  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: rgb(251, 33, 117);
  color: #fff;
  outline: none;
  border: none;
  box-shadow: 0 3px 10px 0 #aaa;
}


.card_wrapper > .card_details > .profile > .button:hover {
  background-color: gray;
  color: #fff;
}

.profile > .button {
  position: relative;
  overflow: hidden;
  background: transparent;
  border-radius: 4px;
  margin: 0 auto;
}

.profile > .button:active {
  transform: scale(.9);
  transition: all 0.2s ease;
}

.profile > .button span {
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.profile > .button:before,
.profile > .button:after {
  content: '';
  position: absolute;
  top: 0.67em;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: .4s, opacity .6s;
  -moz-transition: .4s, opacity .6s;
  -o-transition: .4s, opacity .6s;
  transition: .4s, opacity .6s;
}

/* :before */

.profile > .button:before {
  content: attr(data-hover);
  -webkit-transform: translate(-150%, 0);
  -moz-transform: translate(-150%, 0);
  -ms-transform: translate(-150%, 0);
  -o-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
}

/* :after */

.profile > .button:after {
  content: attr(data-active);
  -webkit-transform: translate(150%, 0);
  -moz-transform: translate(150%, 0);
  -ms-transform: translate(150%, 0);
  -o-transform: translate(150%, 0);
  transform: translate(150%, 0);
}

/* Span on :hover and :active */

.profile > .button:hover span,
.profile > .button:active span {
  opacity: 0;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
}

/*  
    We show :before pseudo-element on :hover 
    and :after pseudo-element on :active 
*/

.profile > .button:hover:before,
.profile > .button:active:after {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition-delay: .2s;
  -moz-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

/* 
  We hide :before pseudo-element on :active
*/

.profile > .button:active:before {
  -webkit-transform: translate(-150%, 0);
  -moz-transform: translate(-150%, 0);
  -ms-transform: translate(-150%, 0);
  -o-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}