.step-9-top {
  margin-top: 2rem;
}

.step9-secondline {
  width: 100%;
  height: 120px;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}