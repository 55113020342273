.step-10 {
  display: flex;
  flex-direction: column;
}

.step-10 > .step-10-h3 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.step-10 > .step-10-h3-line {
  width: 4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-top: 0;
  height: 4px;
  background-color: orange;
}