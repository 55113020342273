.select {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;

  &:not([disabled])::after {
    color: #585858;
    content: '\2303';
    pointer-events: none;
    position: absolute;
    right: 0.4em;
    top: 10%;
    font-size: 1.5em;
    margin-left: 1em;
    transform: rotate(180deg);
  }

  select {
    -webkit-appearance: none;
    border: 1px solid #585858;
    background-color: #FFFFFF;
    display: inline-block;
    outline: none;
    padding: 0.5em 1.4em 0.5em 0.5em;
    position: relative;
    width: 100%;
    height: 3.5em;
  }

  label {
    color: #585858;
    background-color: #FFFFFF;
    padding: .5em;
    font-family: 'Roboto';
    font-size: 1.0em;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: .1em;
    top: .5em;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    z-index: 1;
    border-radius: 10em;
  }
}

.selectError {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;

  &::after {
    color: #940420;
    content: '\2303';
    pointer-events: none;
    position: absolute;
    right: 0.4em;
    top: 10%;
    font-size: 1.5em;
    margin-left: 1em;
    transform: rotate(180deg);
  }

  select {
    -webkit-appearance: none;
    border: 1px solid #940420;
    background-color: #FFFFFF;
    display: inline-block;
    outline: none;
    padding: 0.5em 1.4em 0.5em 0.5em;
    position: relative;
    width: 100%;
    height: 3em;
  }

  label {
    color: #940420;
    background-color: #FFFFFF;
    padding: .5em;
    font-family: 'Roboto';
    font-size: 1.0em;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: .1em;
    top: 1.2em;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    z-index: 1;
  }
}

select:focus {
  outline: none;
}

select:disabled~label,
select:focus~label,
select:not([data-selected="false"]):valid~label {
  top: -.9em;
  left: 1em;
  font-size: .625em;
}

/* active state */
select:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}