.step_four_wrapper {
  max-width: 700px;
  margin: 1rem auto;
  background: #fff;
  position: relative;
  z-index: 10;
  padding: 20px 30px;
}

.step_four_wrapper > .wrap_terms {
  font-size: .8rem;
  margin-bottom: .5rem;
}

.step4-form > p {
  font-size: 1rem;
  margin-top: 2rem;
  padding-bottom: .8rem;
  font-weight: 900;
}

.step4-form > p > .step_four_wrapper > span,
span {
  color: red;
  font-style: italic;
  font-weight: normal;
}

.step4-inputs {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-bottom: 2rem;
}

.step4-inputs > .step4-inputs-one {
  width: 100%;
}

.step4-inputs > .step4-inputs-two {
  width: 100%;
}

.step4-inputs > .step4-inputs-one > strong {
  margin: 0;
  padding: 0;
}

.step4-inputs > .step4-inputs-one > .step4-lab {
  display: block;
  padding: 0 25px;
  text-indent: -15px;
  gap: 1rem;
  align-items: center;
}

.step4-inputs > .step4-inputs-one > .step4-lab > .step4-inp {
  width: 15px;
  height: 15px;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  align-items: center;
}

select {
  border: 1px solid gray;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

form > .cloud {
  font-size: .9rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
  justify-self: center;
  color: gray;
}

form > .cloud:hover {
  color: navy;
}

@media screen and (max-width: 599px) {
  .step_four_wrapper {
    padding: 20px 10px;
  }

  .step4-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
}