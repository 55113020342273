.step14-input {
  opacity: .6;
  cursor: pointer;
}

.step14-input:hover {
  border: none;
}

.step14-price {
  margin: 2rem auto auto;
  line-height: 2rem;
}

.step14-price > p {
  font-weight: bolder;
  letter-spacing: 1.8px;
}

.step14-price > .price {
  color: red;
  font-weight: bolder;
}