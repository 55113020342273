.dashboard {
  width: 100%;
  max-width: 1000px;
  margin: 7rem auto;
  display: block;
  box-shadow: 0 3px 10px 0 #aaa;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #fff;
}

.dashboard > .btn {
  background-color: rgb(186, 208, 232);
  border-radius: 1.5rem;
  margin-left: auto;
  width: 80px;
  padding: 2px 12px;
  font-size: .8rem;
  font-weight: 700;
  text-align: center;
}


.total-scores {
  margin: 1.5rem auto;
}

.total-scores > p,
.total-scores > span {
  font-weight: 900;
}

.total-scores > p > .total-score-score {
  color: red;
  font-weight: 900;
  font-style: normal;
}

.total-scores > p > .total-score-position {
  background-color: red;
  font-style: normal;
  color: #fff;
  padding: 0 6px;
  text-align: center;
  font-weight: 900;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
}

.container-one {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.container-one > .container-one-img > img {
  width: 280px;
  border-radius: .5rem;
  box-shadow: 0 2px 5px 0 #aaa;
  margin-bottom: .5rem;
}

.container-two {
  width: 50%;
  margin-left: auto;
  padding-left: 1rem;
}

.container-one-stats > p > span {
  font-weight: 700;
  color: #111;
  font-style: normal;
  line-height: 2rem;
}

.typo {
  font-weight: 800;
}

.video {
  margin: 2rem auto;
}

@media screen and (max-width: 789px) {
  .dashboard-container {
    display: flex;
    flex-direction: column;
  }

  .container-one {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .container-one > .container-one-img > img {
    width: 100%;
  }

  .container-two {
    width: 100%;
    margin: auto;
  }
}