.step_three_wrapper {
  max-width: 700px;
  margin: 1rem auto;
  background: #fff;
  position: relative;
  z-index: 10;
  padding: 20px 30px;
}

.step_three_wrapper > .wrap_terms {
  font-size: .8rem;
  margin-bottom: .5rem;
}

.warning > h5 {
  font-size: 1rem;
  margin-top: 1.5rem;
}

.warning > p {
  letter-spacing: .8px;
  line-height: 1.5rem;
}