

.registration_btn > button {
  height: 40px;
  width: 100px;
  background-color: orange;
  padding: 6px 12px;
  color: #fff;
  border-radius: 4px;
  text-align: center;
  font-weight: 900;
}

.registration_btn > button:hover {
  background-color: rgb(252, 90, 117);
  color: #111;
  transition: all 0.3s ease-in-out;
}

.registration_btn > .registration_btn-one,
.registration_btn > .registration_btn-one:hover {
  background-color: gray;
}

.registration_btn > button:active {
  transform: scale(.95);
}

.registration_btn > .cloud {
  font-size: 1rem;
  color: gray;
}

.registration_btn > .cloud:hover {
  color: navy;
}
