.floatingLabel,
.floatingLabelError {
  .iconContainer {
    position: absolute;
    z-index: 2;
    & > svg {
      height: 18px;
      width: 18px;
      margin: 1.6em 1em 0;
    }
  }
}

.floatingLabel {
  position: relative;
}

.floatingLabel input[type="text"]:disabled,
.floatingLabel input[type="date"]:disabled,
.floatingLabel input[type="number"]:disabled
.floatingLabel textarea:disabled  {
  color: #6d6d6d;
}

.floatingLabel input[type="text"],
.floatingLabel input[type="date"],
.floatingLabel input[type="number"]
.floatingLabel textarea {
  border: 1px solid #ccc;
  height: 3.5em;
  margin-bottom: 1px;
  padding: 0.5em;
  width: 100%;

  &.withIcon {
    padding-left: 3em;
  }
}

.floatingLabel input[type="text"]:focus,
.floatingLabel input[type="text"]:active,
.floatingLabel input[type="date"]:focus,
.floatingLabel input[type="date"]:active ,
.floatingLabel input[type="number"]:focus,
.floatingLabel input[type="number"]:active 
.floatingLabel textarea:focus,
.floatingLabel textarea:active {
  margin-bottom: 0;
}

.floatingLabel input[type="text"],
.floatingLabel input[type="date"],
.floatingLabel input[type="number"],
.floatingLabel textarea {
  & + label {
    background-color: #ffffff;
    bottom: 0.1em;
    color: #aaa;
    font-family: "Roboto";
    font-size: 1em;
    font-weight: normal;
    left: .5em;
    margin-bottom: 0.75em;
    padding: 0 0.5em;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    transition: margin 0.2s ease, color 0.2s ease, font-size 0.2s ease;
  }

  &.withIcon + label {
    left: 3em;
  }
}

.floatingLabel input[type="text"]:disabled + label,
.floatingLabel input[type="text"]:focus + label,
.floatingLabel input[type="text"]:active + label,
.floatingLabel input[type="text"]:valid + label,
.floatingLabel input[type="date"]:disabled + label,
.floatingLabel input[type="date"]:focus + label,
.floatingLabel input[type="date"]:active + label,
.floatingLabel input[type="date"]:valid + label,
.floatingLabel input[type="number"]:disabled + label,
.floatingLabel input[type="number"]:focus + label,
.floatingLabel input[type="number"]:active + label,
.floatingLabel input[type="number"]:valid + label,
.floatingLabel textarea:disabled + label,
.floatingLabel textarea:focus + label,
.floatingLabel textarea:active + label,
.floatingLabel textarea:valid + label  {
  font-size: 0.625em;
  height: 1em;
  left: .5em;
  margin-bottom: 2em;
  pointer-events: auto;
  top: -0.5em;
  border-radius: 15em;
  color: #585858;
}

.floatingLabel input[type="text"].empty:not(:focus) + label,
.floatingLabel input[type="text"].empty:not(:active) + label,
.floatingLabel input[type="date"].empty:not(:focus) + label,
.floatingLabel input[type="date"].empty:not(:active) + label,
.floatingLabel input[type="number"].empty:not(:focus) + label,
.floatingLabel input[type="number"].empty:not(:active) + label,
.floatingLabel textarea.empty:not(:focus) + label,
.floatingLabel textarea.empty:not(:active) + label  {
  font-size: 1em;
  margin-bottom: 0;
  pointer-events: none;
}

.floatingLabelError {
  position: relative;
}

.floatingLabelError input[type="text"] {
  border: 1px solid #940420;
  height: 3.5em;
  margin-bottom: 1px;
  padding: 0.5em;
  width: 100%;
}

.floatingLabelError input[type="text"]:focus,
.floatingLabelError input[type="text"]:active,
.floatingLabelError input[type="date"]:focus,
.floatingLabelError input[type="date"]:active,
.floatingLabelError input[type="number"]:focus,
.floatingLabelError input[type="number"]:active,
.floatingLabelError textarea:focus,
.floatingLabelError textarea:active {
  margin-bottom: 0;
}

.floatingLabelError input[type="text"] + label {
  background-color: #ffffff;
  top: -1em;
  color: #940420;
  font-family: "Roboto";
  font-size: 1em;
  font-weight: normal;
  left: .5em;
  margin-bottom: 0.75em;
  padding: 0 0.5em;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  transition: margin 0.2s ease, color 0.2s ease, font-size 0.2s ease;
}

.floatingLabelError input[type="text"]:focus + label,
.floatingLabelError input[type="text"]:active + label,
.floatingLabelError input[type="text"]:valid + label,
.floatingLabelError input[type="date"]:focus + label,
.floatingLabelError input[type="date"]:active + label,
.floatingLabelError input[type="date"]:valid + label,
.floatingLabelError input[type="number"]:focus + label,
.floatingLabelError input[type="number"]:active + label,
.floatingLabelError input[type="number"]:valid + label {
  font-size: 0.625em;
  height: 1em;
  left: 1em;
  margin-bottom: 2em;
  pointer-events: auto;
  top: -0.5em;
  border-radius: 15em;
}

.floatingLabelError input[type="text"].empty:not(:focus) + label,
.floatingLabelError input[type="text"].empty:not(:active) + label,
.floatingLabelError input[type="date"].empty:not(:focus) + label,
.floatingLabelError input[type="date"].empty:not(:active) + label, 
.floatingLabelError input[type="number"].empty:not(:focus) + label,
.floatingLabelError input[type="number"].empty:not(:active) + label  {
  font-size: 1em;
  margin-bottom: 0;
  pointer-events: none;
}




.floatingLabel,
.floatingLabelError {
  .iconContainer {
    position: absolute;
    z-index: 2;
    & > svg {
      height: 18px;
      width: 18px;
      margin: 1.6em 1em 0;
    }
  }
}

.floatingLabel {
  position: relative;
}

.floatingLabel input[type="email"]:disabled {
  color: #6d6d6d;
}

.floatingLabel input[type="email"] {
  border: 1px solid #ccc;
  height: 3.5em;
  margin-bottom: 1px;
  padding: 0.5em;
  width: 100%;

  &.withIcon {
    padding-left: 3em;
  }
}

.floatingLabel input[type="email"]:focus,
.floatingLabel input[type="email"]:active {
  margin-bottom: 0;
}

.floatingLabel input[type="email"] {
  & + label {
    background-color: #ffffff;
    bottom: 0.1em;
    color: #ccc;
    font-family: "Roboto";
    font-size: 1em;
    font-weight: normal;
    left: .5em;
    margin-bottom: 0.75em;
    padding: 0 0.5em;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    transition: margin 0.2s ease, color 0.2s ease, font-size 0.2s ease;
  }

  &.withIcon + label {
    left: 3em;
  }
}

.floatingLabel input[type="email"]:disabled + label,
.floatingLabel input[type="email"]:focus + label,
.floatingLabel input[type="email"]:active + label,
.floatingLabel input[type="email"]:valid + label {
  font-size: 0.625em;
  height: 1em;
  left: .5em;
  margin-bottom: 2em;
  pointer-events: auto;
  top: -0.5em;
  border-radius: 15em;
}

.floatingLabel input[type="email"].empty:not(:focus) + label,
.floatingLabel input[type="email"].empty:not(:active) + label {
  font-size: 1em;
  margin-bottom: 0;
  pointer-events: none;
}

.floatingLabelError {
  position: relative;
}

.floatingLabelError input[type="email"] {
  border: 1px solid #940420;
  height: 3.5em;
  margin-bottom: 1px;
  padding: 0.5em;
  width: 100%;
}

.floatingLabelError input[type="email"]:focus,
.floatingLabelError input[type="email"]:active {
  margin-bottom: 0;
}

.floatingLabelError input[type="email"] + label {
  background-color: #ffffff;
  top: -1em;
  color: #940420;
  font-family: "Roboto";
  font-size: 1em;
  font-weight: normal;
  left: .5em;
  margin-bottom: 0.75em;
  padding: 0 0.5em;
  pointer-events: none;
  position: absolute;
  z-index: 1;
  transition: margin 0.2s ease, color 0.2s ease, font-size 0.2s ease;
}

.floatingLabelError input[type="email"]:focus + label,
.floatingLabelError input[type="email"]:active + label,
.floatingLabelError input[type="email"]:valid + label {
  font-size: 0.625em;
  height: 1em;
  left: 1em;
  margin-bottom: 2em;
  pointer-events: auto;
  top: -0.5em;
  border-radius: 15em;
}

.floatingLabelError input[type="email"].empty:not(:focus) + label,
.floatingLabelError input[type="email"].empty:not(:active) + label {
  font-size: 1em;
  margin-bottom: 0;
  pointer-events: none;
}