.leader {
  margin: 0 auto 2rem auto;
  max-width: 900px;
  box-shadow: 0 3px 6px 0 #aaa;
  padding-bottom: 1rem;
}

.leader_head {
  display: flex;
  padding: 1rem;
  flex-direction: row;
  background-color: #4c1864;
  width: 100%;
  cursor: pointer;
}

.leader_head > .leader_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 1rem;
  border: 2px solid #fff;
}

.details {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
}

.leader_details {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
}

.details > .state {
  color: #fff;
  margin-inline: auto;
  font-size: 1.2rem;
}

/* OTHER INDIVIDUAL  IMAGES AND STATS */
.other_details {
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.other_details:hover > .details_wrap {
  border-color: orange;
}

.other_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid gray;
}

.details_wrap {
  margin-left: -2rem;
  display: flex;
  /* width: 90%; */
  flex-direction: row;
  border: 2px solid #4c1864;
  padding: .5rem;
  padding-left: 1rem;
  border-radius: 20px;
  z-index: -1;
}

.details_wrap > .state {
  margin-left: auto;
}

.details_wrap > .icon {
  margin-left: 3.5rem;
  color: #00a37d
}

.details_wrap > .red {
  color: red;
}

.details_wrap > p {
  margin-left: 2rem;
}

.stat {
  margin-left: auto;
  padding-right: .5rem;
}
