.radio {
  min-height: 3em;
  width: 100%;

  input[type="radio"] {
    height: 0;
    opacity: 0;
    width: 0;
    &:checked + .radioControl {
      background: radial-gradient(#fb2175 50%, rgba(255, 0, 0, 0) 51%);
      border: 0.1em solid #fb2175;
    }
  }

  .radioControl {
    border-radius: 50%;
    border: 0.1em solid #6d6d6d;
    display: block;
    height: 1em;
    margin-left: 0.5em;
    width: 1em;
  }

  .radioContainer {
    align-items: center;
    display: flex;
    width: 100%;

    &.disabled {
      color: #6d6d6d;
      label {
        color: #6d6d6d;
      }
    }
  }

  .contentContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;
    padding: 1em 0;
  }

  .row {
    display: flex;
  }

  label {
    display: inline-block;
    font-size: 1em;
    margin-left: .75em;
    margin-top: .2em;
  }
}
