.step_one_wrapper {
  max-width: 700px;
  margin: 1rem auto;
  background: #fff;
  position: relative;
  z-index: 10;
  padding: 20px 30px;
}

.step_one_wrapper > .wrap_terms {
  font-size: .8rem;
  margin-bottom: .5rem;
}

.step_one_wrapper > h2 {
  margin: 2rem auto;
  font-size: 2rem;
  font-weight: 900;
}

.step_one_wrapper > p {
  margin-bottom: 1rem;
  letter-spacing: .8px;
  line-height: 1.5rem;
}

form > label {
  display: block;
  padding: 15px;
  text-indent: -15px;
}

form > label > input {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-right: 10px;
}

@media screen and (max-width: 599px) {
  .step_one_wrapper {
    padding: 20px 16px;
  }
}