.login_wrapper {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.form {
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 1px solid rgb(169, 169, 237);
  padding: 2rem;
  padding-top: 0;
  border-radius: 4px;
  max-width: 480px;
}

.form > img {
  margin: 1rem auto;
  max-width: 70%;
  min-width: 60%;
}

.form-item {
  position: relative;
  margin-bottom: 2rem;
}


input {
  width: 100%;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
  font-size: clamp(15px, 1.5vw, 18px);
}

/* Button */
.login-btn {
  width: 100%;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  line-height: 42px;
  padding: 0;
  border: none;
  background: #f54;
  transition: all 0.4s ease;
}

.login-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transform: translateY(-2px);
}

.login-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.btn-5 {
  background: #f54;
  background: linear-gradient(0deg, rgb(224, 193, 190) 0%, rgb(239, 186, 163) 100%);
  color: #777;
}

.login-btn:active {
  transform: scale(.9);
}

.reg {
  text-align: center;
  padding-top: 1rem;
  font-size: .9rem;
}

.reg > p > a {
  color: #f0094a;
}

.reg > p > a:hover {
  color: navy;
}

@media screen and (max-width: 899px) {

  .form {
    margin-top: 70px;
  }
}

@media screen and (max-width: 499px) {

  .form {
    padding: 1rem;
    max-width: 100%;
    margin-top: 80px;
  }
}