@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Roboto:wght@100;400&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  background-color: aliceblue;
  color: #000;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
}