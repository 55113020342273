*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.const_head {
  width: 100%;
  overflow: hidden;
  /* position: relative; */
  background-position: center;
  background-size: cover;
  display: flex;
  /* position: relative; */
}

.const_head > .banner_img {
  object-fit: cover;
  /* margin-top: -12rem; */
  z-index: -1;
  opacity: .8;
}


.const_title {
  font-family: "Oswald",
    Sans-serif;
  --bg-size: 400%;
  --color-one: orange;
  --color-two: rgb(241, 222, 183);
  font-size: clamp(2rem, 10vmin, 4rem);
  background: linear-gradient(90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .const_title {
    animation: move-bg 8s linear infinite;
    font-family: "Oswald",
      Sans-serif;
  }

  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
      font-family: "Oswald",
        Sans-serif;
    }
  }
}

.const_sub_title {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  text-shadow: black 0px 2px 2px;
  align-items: center;
}

.const_sub_title > a {
  font-size: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  color: orange;
  font-weight: 800;
  font-family: "Oswald",
    Sans-serif;
  text-shadow: black 0px 3px 3px;
  text-align: center;
}


.const_sub_title > a:hover {
  color: #fff;
}

.const_sub_title > p {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 800;
  font-family: "Oswald",
    Sans-serif;
  text-shadow: black 0px 2px 2px;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

/* Vote Button */
/* button */
.frame {
  position: absolute;
  top: .7rem;
  left: .7rem;
}

.custom-btn {
  width: 100px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-size: 1rem;
  font-weight: 900;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: .3rem;
}

.btn-11 {
  border: none;
  background: rgb(251, 33, 117);
  background: linear-gradient(0deg, rgba(251, 33, 117, 1) 0%, rgba(234, 76, 137, 1) 100%);
  color: #fff;
  overflow: hidden;
}

.btn-11:hover {
  text-decoration: none;
  color: #fff;
}

.btn-11:hover {
  opacity: .7;
}

.btn-11:active {
  transform: scale(.9);
}

/* Mobile View */

@media screen and (max-width: 1299px) {
  .const_title {
    top: 50%;
  }
}

@media screen and (max-width: 999px) {
  .const_head > .banner_img {
    object-fit: cover;
    z-index: -1;
    opacity: .8;
  }

  .const_title {
    display: flex;
    flex-direction: column;
  }

  .const_title > h2 {
    font-family: "Oswald",
      Sans-serif;
    font-size: 5rem;
    font-weight: 900;
  }

  .const_sub_title {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .cards {
    max-width: 790px;
    margin: 5rem auto;
    grid-template-columns: 1fr 1fr;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 599px) {
  .const_title > h2 {

    font-size: 2rem;
    font-weight: 900;
  }

  .cards {
    max-width: 790px;
    margin: 5rem auto;
    //grid-template-columns: 1fr;
    //flex-wrap: nowrap;
  }

  .const_sub_title > a,
  .const_sub_title > p {
    font-size: 1rem;
  }

  .const_title {
    top: 70%;
  }
}