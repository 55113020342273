.profile_header {
  object-fit: cover;
}

.profile_header > h2 {
  font-size: 3rem;
  text-align: center;
  margin-top: -5rem;
  color: #fff;
  font-weight: 900;
}

.profile_hero {
  max-width: 1200px;
  margin: auto;
  padding: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.profile_hero_details {
  /* padding: 2rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  border-radius: 8px;
  position: relative;
}

.profile_details {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}

.profile_details > .profile_details_icons {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  margin-inline: auto;
  margin-top: -1rem;
  align-items: center;
}

.profile_details > h4 {
  color: #111;
  background-color: orange;
  padding: 4px 50px;
  border-radius: 10px;
  font-weight: 800;
  text-align: center;
  margin-inline: auto;
  margin-bottom: 1rem;
  text-transform: uppercase
}

.profile_details > h2 {
  color: #fff;
  font-size: 2.2rem;
  margin-bottom: .5rem;
  text-align: center;
  font-weight: 800;
  text-transform: uppercase
}

.profile_hero_details > .profile_votes {
  display: flex;
  margin: 0 auto;
  color: #111;
}

.profile_votes,
table.table_one {
  width: 100%;
  margin: 1rem 2rem;
}

.mb-4 {
  width: 35%;
  margin: 0 auto .5rem auto;
  font-weight: 900;
}


/* RIGTH SECTION */

.profile_infos {
  margin: 1rem;
  width: 100%;
}

.profile_infos_details > h3 {
  color: #111;
  font-size: 1.3rem;
  font-weight: 800;
}

.profile_infos_details > ul {
  color: #111;
  list-style-type: disc;
  margin: 1rem;
}

.profile_infos_details > ul > li {
  padding-bottom: .5rem;
}

.profile_infos > h2 {
  color: #111;
  font-size: 1.4rem;
  font-weight: 900;
  margin-bottom: 1rem;
  text-align: left;
}

@media screen and (max-width:799px) {
  .profile_hero {
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .profile_header > h2 {
    font-size: 2rem;
    margin-top: -5rem;
  }

  .profile_hero_details {
    max-width: 100%;
  }

  .profile_infos {
    margin: 1rem auto;
  }

  .profile_infos_details > ul {
    margin: 1rem 2rem;
  }
}

@media screen and (max-width: 499px) {
  .profile_hero {
    padding: 20px 10px;
  }

  .profile_details > h2 {
    font-size: 1.7rem;
    font-weight: 800;
  }
}