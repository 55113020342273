footer {
  background-color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  padding-top: 1.5rem;
  padding-bottom: 0;
  gap: 1rem;
  width: 100%;
}

.copy_right {
  width: 100%;
  align-items: center;
  background: #111;
  padding-bottom: 2rem;
}

.copy_right > p {
  padding: 1rem auto;
  text-align: center;
  color: orange;
}