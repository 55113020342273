.checkboxWrapper {
  display: flex;

  .checkboxText {
    margin-bottom: 0;
    padding-left: 0.625em;
  }

  label {
    margin-bottom: 0;
  }

  label.checkbox input[type="checkbox"] {
    display: none;
  }

  label.checkbox span {
    background: #ffffff;;
    border: 2px solid #BBB;
    display: inline-block;
    height: 1em;
    margin: 3px;
    position: relative;
    transition: width 0.1s, height 0.1s, margin 0.1s;
    vertical-align: middle;
    width: 1em;
  }

  label.checkbox :checked + span {
    background:#fb2175;
    border: 2px solid #fb2175;
    height: 1em;
    margin: 2px;
    width: 1em;
  }

  label.checkbox :checked + span:after {
    color: #ffffff;
    content: '✓';
    font-size: .825em;
    position: absolute;
    top: -2px;
  }
}

