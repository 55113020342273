.step_two_wrapper {
  max-width: 700px;
  margin: 1rem auto;
  background: #fff;
  position: relative;
  z-index: 10;
  padding: 20px 30px;
}


.step_two_wrapper > .wrap_terms {
  font-size: .8rem;
  margin-bottom: .5rem;
}

.step_two_wrapper > h4 {
  font-size: 1.5rem;
  margin: 1rem auto;
  font-weight: 700;
}

.step2_paragraphs > p {
  margin-bottom: 1rem;
  letter-spacing: .8px;
  line-height: 1.5rem;
}

.step_two_wrapper > .step2_paragraphs {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.step_two_wrapper > .step2_paragraphs > p {
  align-self: center;
  margin: .5rem;
}

.step2_paragraphs > span {
  color: blueviolet;
}

@media screen and (max-width: 599px) {
  .step_two_wrapper {
    padding: 20px 16px;
  }
}